import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Main from "./layout/Main";
import "./assets/css/style.css";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import ShortLinkRedirect from "./layout/ShortLinkRedirect";
// import ErrorPage from "./layout/ErrorPage";

function App() {
  return (
    <Router>
      { /^\/[a-zA-Z0-9]{7}$/.test(window.location.pathname) ? null : <Header/>}
      <Switch>
        <Route path="/" exact component={Main} />
        <Route path="/ddd" exact component={Main} />
        <Route path="/:hash" component={ShortLinkRedirect} />
        {/* <Route path="*" component={ErrorPage} /> */}
      </Switch>
      { /^\/[a-zA-Z0-9]{7}$/.test(window.location.pathname) ? null : <Footer/>}
    </Router>
  );
}

export default App;
