import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

const LinkResult = ({ inputValue }) => {
  const [shortenLink, setShortenLink] = useState("");
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();

  function RedirectExample() {
    const timeout = setTimeout(() => {
      history.push(`${shortenLink.url_key}`);
    }, 500);

    return () => clearTimeout(timeout);
  }

  const fetchData = async () => {
    const api_url = process.env.API_URL || "https://api.crispurl.com/"

    try {
      setLoading(true);
      const res = await axios.post(api_url + "shortener", {
        long_url: inputValue,
      });
      setShortenLink(res.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (inputValue.length) {
      fetchData();
    }
  }, [inputValue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [copied]);

  if (loading) {
    return (
      <div
        className="loader"
        style={{
          marginLeft: "15rem",
        }}
      >
        <p>Loading</p>
      </div>
    );
  }
  if (error) {
    return (
      <div
        style={{
          marginLeft: "15rem",
        }}
      >
        <p>Error</p>
      </div>
    );
  }

  return (
    <>
    {shortenLink && (
    <div className="col-lg-6 col-md-6 mb-4">
      <div className="login-area">
        <h4 className="text-secondary">Shortened URL</h4>
        <div className="col">
          <label></label>
          
          <div class="input-group">
            <input type="text" class="form-control" disabled placeholder={shortenLink.shortened_url}/>
            <button onClick={() =>  navigator.clipboard.writeText(shortenLink.shortened_url)} class="btn btn-outline-secondary" type="button"><i class="fa fa-clone"></i></button>
          </div>
          <div
            className="text-left"
            style={{ marginTop: "1rem", display: "flex" }}
          >
            <button
              className="btn btn-primary btnhover w-100 me-2"
              onClick={RedirectExample}
            >
              Redirect
            </button>
          </div>
        </div>
      </div>
    </div>
    )}
      {/* {shortenLink && (
        <div className="col-lg-6 col-md-6 mb-4">
          <div className="login-area">
            <div>
              <h4 className="dz-title">Your Crisp URL</h4>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p>{shortenLink.shortened_url}</p>
              </div>
              <div>
                <CopyToClipboard
                  text={shortenLink.shortened_url}
                  onCopy={() => setCopied(true)}
                >
                  <Link
                    style={{ color: "black" }}
                    className="fa fa-clone"
                  ></Link>
                </CopyToClipboard>
              </div>
            </div>
            <div>
              <button
                className="btn btn-primary btnhover w-100 me-2 submit-button"
                onClick={RedirectExample}
              >
                Redirect
              </button>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default LinkResult;
