export const MenuListArray2 = [
  {
    title: "MyUrl",
    // to: "",
  },
  {
    title: "Features",
    // content: [
    //   {
    //     title: "My Profile",
    //     to: "./my-profile",
    //   },
    //   {
    //     title: "Services",
    //     to: "./services",
    //   },
    //   {
    //     title: "FAQ's",
    //     to: "./faq",
    //   },
    // ],
  },

  {
    title: "Help",
    // to: "./about-us",
  },
];
