import React, { useState } from "react";
//images

//import logo from './../assets/images/logo.png';

//function slideDown(el) {
//	console.log(el);
//	const elem = document.getElementById(el);
//	console.log(elem);
//	document.getElementById("myH1").style.color = "red";
//	elem.style.transition = "all 2s ease-in-out";
//	//elem.style.height = "400px";
//}

function Footer({}) {
  //const [open, setOpen] = useState(false);
  return (
    <>
      <footer className={"site-footer"}>
        <div className="footer-bottom">
          <div className="container">
            <div className="row fb-inner">
              <div className="col-lg-3 col-md-12 text-start">
                <p className="copyright-text">Copyright @ Crispurl</p>
              </div>
              <div className="col-lg-3 col-md-12 text-start">
                <p className="copyright-text">Terms</p>
              </div>
              <div className="col-lg-3 col-md-12 text-start">
                <p className="copyright-text">Privacy Policy</p>
              </div>
              <div className="col-lg-3 col-md-12 text-start">
                <p className="copyright-text">Accessibility</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
