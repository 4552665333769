import { useEffect, useRef } from "react";

const ShortLinkRedirect = (hashData) => {
  const dataFetchedRef = useRef(false);
  const api_url = process.env.API_URL || "https://api.crispurl.com/"

  useEffect(() => {
    // if (loading === false) {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // console.log("loading");
    window.location.href = `${api_url}${hashData.location.pathname}`;
    // }
  }, []);

  return null;
};

export default ShortLinkRedirect;

// old code
// import { useEffect, useState, useRef } from "react";

// import axios from "axios";

// const ShortLinkRedirect = (hashData) => {
//   const [shortenLink, setShortenLink] = useState("");
//   const [loading, setLoading] = useState(false);
//   const dataFetchedRef = useRef(false);

//   const fetchUrl = async () => {
//     try {
//       const res = await axios.get(
//         `https://api.crispurl.com${hashData.location.pathname}`
//       );
//       setShortenLink(res.data.target_url);
//       console.log(res.data.target_url);
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   useEffect(() => {
//     // if (loading === false) {
//     if (dataFetchedRef.current) return;
//     dataFetchedRef.current = true;
//     console.log("loading");
//     fetchUrl();
//     // }
//   }, []);

//   //   console.log(shortenLink);
//   //   console.log(shortenLink != null);

//   // Navigate to the full URL
//   if (shortenLink.length > 0) window.location = shortenLink;
//   return null;
// };

// export default ShortLinkRedirect;
